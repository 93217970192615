import Layouts from '../../components/Layouts'
import { Row, Col, Card, Form, Select, Button, DatePicker, message, Badge, Skeleton, Switch, Table, Divider, Popover } from 'antd';
import { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import API from '../../util/Api';
import Api from '../../util/Api/Api';
import { get, isArray, isPlainObject } from 'lodash';
import moment from 'moment';
import Head from 'next/head';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { ReloadOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router'
import { render } from 'react-dom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Dashboard = () => {
    const router = useRouter()
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const listMonth = [
        {
            id: "01",
            value: "มกราคม"
        },
        {
            id: "02",
            value: "กุมภาพันธ์"
        },
        {
            id: "03",
            value: "มีนาคม"
        },
        {
            id: "04",
            value: "เมษายน"
        },
        {
            id: "05",
            value: "พฤษภาคม"
        },
        {
            id: "06",
            value: "มิถุนายน"
        },
        {
            id: "07",
            value: "กรกฏาคม"
        },
        {
            id: "08",
            value: "สิงหาคม"
        },
        {
            id: "09",
            value: "กันยายน "
        },
        {
            id: "10",
            value: "ตุลาคม"
        },
        {
            id: "11",
            value: "พฤศจิกายน"
        },
        {
            id: "12",
            value: "ธันวาคม"
        }]
    const listQuarter = [
        {
            id: "01",
            value: "ไตรมาสที่ 1"
        },
        {
            id: "02",
            value: "ไตรมาสที่ 2"
        },
        {
            id: "03",
            value: "ไตรมาสที่ 3"
        },
        {
            id: "04",
            value: "ไตรมาสที่ 4"
        }]

    const [filter, setFilter] = useState({
        // ad: null,
        // rd: null,
        // is_ad: false,
        month: listMonth,
        quarter: listQuarter,
        region: [],
        prov: [],
    })
    const [filter_ad, setfilter_ad] = useState(null)
    const [filter_rd, setfilter_rd] = useState(null)
    const [filter_quarter, setfilter_quarter] = useState(null)
    const [filter_is_ad, setfilter_is_ad] = useState(false)
    const [showSummaryDashboard, setShowSummaryDashboard] = useState(true)
    const [showProductScreenDashboard, setShowProductScreenDashboard] = useState(false)
    const [showStockDashboard, setShowStockDashboard] = useState(false)


    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            await getDashboardConfig("init")
            // await reset()
        } catch (error) {

        }
    }




    const reset = async () => {
        const date = moment(new Date());

        const yearDate = date.format('YYYY');
        const monthDate = date.format('MM');
        form.setFieldsValue({
            year: date,
            month: [monthDate],
            quarter: null,
            prov: null,
            region: null,
        })
        setFilter({ ...filter, prov: [] })
        // setfilter_ad(null)
        // setfilter_rd(null)
        await getDashboard({ month: [monthDate], quarter: null, year: yearDate, ad: null, rd: null });
    }


    const [adOver, setAdOver] = useState(0)
    const [adOve1, setAdOve1] = useState(0)
    const [rdOver, setRdOver] = useState(0)
    const [configDashboard, setConfigDashboard] = useState(null)
    const [typeAdOver, setTypeAdOver] = useState("เดือน")


    const getDashboardConfig = async (init) => {
        try {
            const date = moment(new Date());
            let data;
            if (init == "init") {
                const res = await Api.get(`/config/all?limit=9999&page=1&search_year=${date.format('YYYY')}`);
                data = res.data
            } else {
                const res = await API.get(`/config/all?limit=9999&page=1&search_year=${date.format('YYYY')}`);
                data = res.data
            }


            let ad_over = 0, ad_over_1 = 0, rd_over = 0;
            if (isArray(data.data.data) && data.data.data.length > 0) {
                // console.log('data config', data.data.data[0])
                const { config } = data.data.data[0];
                if (isPlainObject(config)) {

                    ad_over = config.NEXT_PUBLIC_AD_ACTIVE_MONTH;
                    ad_over_1 = config.NEXT_PUBLIC_AD_ACTIVE_1;
                    rd_over = config.NEXT_PUBLIC_RD_ACTIVE_MONTH;

                    setAdOver(ad_over);
                    setAdOve1(ad_over_1);
                    setRdOver(rd_over);
                    setConfigDashboard(config)
                }
            } else {
                message.warning("ไม่มีการ ตั้งค่าข้อมูลพื้นฐาน Dashboard กรุณาตั้งค่าด้วย")
            }

            const yearDate = date.format('YYYY');
            const monthDate = date.format('MM');
            form.setFieldsValue({
                year: date,
                month: [monthDate],
                quarter: null,
                ad: null,
                rd: null,
                prov: null,
                region: null,
            })
            onChangeMonth([monthDate])
            setFilter({ ...filter, prov: [] })

            await getDashboard({ month: [monthDate], quarter: null, year: yearDate, ad: null, rd: null, ad_over, ad_over_1, rd_over });
            await getFilter()
        } catch (error) {
            console.log('error', error)
        }
    }

    const getFilter = async () => {
        try {
            const [value1, value2] = await Promise.all([getDealers(), getMasterRegion()])
            setfilter_rd(value1)
            setFilter({ ...filter, region: value2 })

        } catch (error) {

        }
    }

    const onChangeRd = async (id) => {
        try {
            // console.log('onChangeRd', id)
            if (id) {
                const { data } = await API.get(`/customer/bydealerid/${id}`);
                if (data.status === "successful") {
                    setfilter_ad(data.data)
                }
            } else {
                setfilter_ad([])
            }

            form.setFieldsValue({
                ad: null,
                prov: null,
                region: null,
            })
        } catch (error) {

        }
    }

    const [monthDisable, setMonthDisable] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
    ])


    const onChangeMonth = async (month) => {
        try {
            if (month.includes('01') || month.includes('02') || month.includes('03')) {

                setMonthDisable([
                    false,
                    false,
                    false,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true
                ])

            } else if (month.includes('04') || month.includes('05') || month.includes('06')) {
                setMonthDisable([
                    true,
                    true,
                    true,
                    false,
                    false,
                    false,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true
                ])
            } else if (month.includes('07') || month.includes('08') || month.includes('09')) {
                setMonthDisable([
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false,
                    true,
                    true,
                    true
                ])
            } else if (month.includes('10') || month.includes('11') || month.includes('12')) {
                setMonthDisable([
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    true,
                    false,
                    false,
                    false
                ])
            } else {
                setMonthDisable([
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                ])
            }



            // form.setFieldsValue({
            //     ad: null,
            //     prov: null,
            //     region: null,
            // })
        } catch (error) {

        }
    }
    const [disabled_prov, setdisabled_prov] = useState(false)
    const [disabled_region, setdisabled_region] = useState(false)

    const onChangeAd = async (id) => {
        try {
            // console.log('onChangeAd', id)
            // console.log('filter_ad', filter_ad)
            if (id) {
                if (isArray(filter_ad)) {
                    const find = filter_ad.find(where => where.id === id)
                    if (isPlainObject(find)) {
                        // console.log('find', find)
                        const { MapRegProv } = find;
                        if (isPlainObject(MapRegProv)) {
                            await onChangeRegion(MapRegProv.reg_id)
                            const _find = filter.region.find(where => where.id === MapRegProv.reg_id)
                            if (_find) {
                                form.setFieldsValue({
                                    prov: MapRegProv.prov_id,
                                    region: MapRegProv.reg_id,
                                })
                                setdisabled_prov(true)
                                setdisabled_region(true)
                            } else {
                                form.setFieldsValue({
                                    prov: null,
                                    region: null,
                                })
                                setdisabled_prov(false)
                                setdisabled_region(false)
                            }

                        }
                    }
                }

            } else {
                form.setFieldsValue({
                    prov: null,
                    region: null,
                })
                setdisabled_prov(false)
                setdisabled_region(false)
            }
        } catch (error) {

        }
    }



    const getDealers = async () => {
        const { data } = await API.get(`/dealers/all?limit=9999999&page=1&status=active`);
        return (data.status === "success") ? data.data.data : []
    }

    /* เรียกข้อมูล สินค้าประเภท ทั้งหมด */
    const getMasterRegion = async () => {
        const { data } = await API.get(`/master/region/all?limit=${99999}&page=${1}&status=active`)
        return data.data.data
    }

    const [productScreenSalesFront, setProductScreenSalesFront] = useState({
        data: [
            ['ไซส์', 'คำอธิบาย', 'จำนวน (เส้น)', 'จำนวน AD ที่ซื้อ'],
        ],
        colors: ['#FF6384']
    })
    const [productScreenSalesRear, setProductScreenSalesRear] = useState({
        data: [
            ['ไซส์', 'คำอธิบาย', 'จำนวน (เส้น)', 'จำนวน AD ที่ซื้อ'],
        ],
        colors: ['#FF6384']
    })

    const [productScreenAdSalesFront, setProductScreenAdSalesFront] = useState({
        data: [
            ['รหัส AD', 'ชื่อร้าน', 'จำนวนไซส์ที่แนะนำ', 'จำนวนไซส์ที่ขาย', '%'],
        ],
        colors: ['#FF6384']
    })
    const [productScreenAdSalesRear, setProductScreenAdSalesRear] = useState({
        data: [
            ['รหัส AD', 'ชื่อร้าน', 'จำนวนไซส์ที่แนะนำ', 'จำนวนไซส์ที่ขาย', '%'],
        ],
        colors: ['#FF6384']
    })

    const [productScreenGraphAverageFront, setProductScreenGraphAverageFront] = useState({
        labels: [],
        datasets: []
    })
    const [productScreenGraphAverageRear, setProductScreenGraphAverageRear] = useState({
        labels: [],
        datasets: []
    })

    const [productScreenProvinceSalesFront, setProductScreenProvinceSalesFront] = useState({
        data: [
            ['จังหวัด', '70/90-17', '80/90-14', '90/80-14', '110/70-12', '110/70-13', '110/70-14', '120/70-15'],
        ],
        colors: ['#FF6384']
    })
    const [productScreenProvinceSalesRear, setProductScreenProvinceSalesRear] = useState({
        data: [
            ['จังหวัด', '80/90-17', '90/90-14', '100/80-14', '130/70-13', '140/70-14'],
        ],
        colors: ['#FF6384']
    })

    const [productScreenAdSalesStatusFront, setProductScreenAdSalesStatusFront] = useState({
        data: [
            ['ชื่อร้าน', '70/90-17', '80/90-14', '90/80-14', '110/70-12', '110/70-13', '110/70-14', '120/70-15'],
        ],
        colors: ['#FF6384']
    })
    const [productScreenAdSalesStatusRear, setProductScreenAdSalesStatusRear] = useState({
        data: [
            ['ชื่อร้าน', '80/90-17', '90/90-14', '100/80-14', '130/70-13', '140/70-14'],
        ],
        colors: ['#FF6384']
    })

    const getProductScreenSales = async (month = [], year = "", filter_rd = "", filter_ad = "", filter_region = null, filter_province = null, which = "") => {
        let url = `/dashboard/sales/${which}?`
        // if (month) url += `&month=${month}`
        if (year) url += `&year=${year}`
        if (filter_rd) url += `&filter_rd=${filter_rd}`
        if (filter_ad) url += `&filter_ad=${filter_ad}`
        if (filter_region) url += `&filter_region=${filter_region}`
        if (filter_province) url += `&filter_province=${filter_province}`
        if (isArray(month)) {
            month.forEach(e => {
                url += `&month=${e}`
            });
        }

        const { data } = await API.get(url)
        switch (which) {
            case "frontWheel":
                if (data.data.length > 0) {
                    let model = {
                        data: [
                            ['ไซส์', 'คำอธิบาย', 'จำนวน (เส้น)', 'จำนวน AD ที่ซื้อ'],
                        ],
                        colors: ['#FF6384']
                    }

                    if (isArray(data.data) && data.data.length > 0) {
                        data.data.forEach(e => {
                            if (e) model.data.push([e.size, e.fitment, e.amount, e.amount_ad_purchased])
                        });
                        model.colors = ['#28A228']
                    }

                    // model.data = data.data
                    // console.log(model)
                    await setProductScreenSalesFront(model)
                }
                break;
            case "backWheel":
                if (data.data.length > 0) {
                    let model = {
                        data: [
                            ['ไซส์', 'คำอธิบาย', 'จำนวน (เส้น)', 'จำนวน AD ที่ซื้อ'],
                        ],
                        colors: ['#FF6384']
                    }

                    if (isArray(data.data) && data.data.length > 0) {
                        data.data.forEach(e => {
                            if (e) model.data.push([e.size, e.fitment, e.amount, e.amount_ad_purchased])
                        });
                        model.colors = ['#28A228']
                    }

                    // model.data = data.data
                    // console.log(model)
                    await setProductScreenSalesRear(model)
                }
                break;
        }

    }
    const getProductScreenAdSales = async (month = [], year = "", filter_rd = "", filter_ad = "", filter_region = null, filter_province = null, which = "") => {
        let url = `/dashboard/adSales/${which}?`
        // if (month) url += `&month=${month}`
        if (year) url += `&year=${year}`
        if (filter_rd) url += `&filter_rd=${filter_rd}`
        if (filter_ad) url += `&filter_ad=${filter_ad}`
        if (filter_region) url += `&filter_region=${filter_region}`
        if (filter_province) url += `&filter_province=${filter_province}`
        if (isArray(month)) {
            month.forEach(e => {
                url += `&month=${e}`
            });
        }
        const { data } = await API.get(url)

        switch (which) {
            case "frontWheel":
                if (data.data.length > 0) {
                    let model = {
                        data: [
                            ['รหัส AD', 'ชื่อร้าน', 'จำนวนไซส์ที่แนะนำ', 'จำนวนไซส์ที่ขาย', '%'],
                        ],
                        colors: ['#FF6384']
                    }

                    if (isArray(data.data) && data.data.length > 0) {
                        data.data.forEach(e => {
                            if (e) model.data.push([e.code_id, e.customer_name, e.amount_recommend_size, e.amount_sale_size, e.percent])
                        });
                        model.colors = ['#28A228']
                    }

                    // model.data = data.data
                    // console.log(model)
                    await setProductScreenAdSalesFront(model)
                }
                break;
            case "backWheel":
                if (data.data.length > 0) {
                    let model = {
                        data: [
                            ['รหัส AD', 'ชื่อร้าน', 'จำนวนไซส์ที่แนะนำ', 'จำนวนไซส์ที่ขาย', '%'],
                        ],
                        colors: ['#FF6384']
                    }

                    if (isArray(data.data) && data.data.length > 0) {
                        data.data.forEach(e => {
                            if (e) model.data.push([e.code_id, e.customer_name, e.amount_recommend_size, e.amount_sale_size, e.percent])
                        });
                        model.colors = ['#28A228']
                    }

                    // model.data = data.data
                    // console.log(model)
                    await setProductScreenAdSalesRear(model)
                }
                break;
        }

    }
    const getProductScreenGraphAverage = async (month = [], year = "", filter_rd = "", filter_ad = "", filter_region = null, filter_province = null, which = "") => {
        let url = `/dashboard/graphAverage/${which}?`
        // if (month) url += `&month=${month}`
        if (year) url += `&year=${year}`
        if (filter_rd) url += `&filter_rd=${filter_rd}`
        if (filter_ad) url += `&filter_ad=${filter_ad}`
        if (filter_region) url += `&filter_region=${filter_region}`
        if (filter_province) url += `&filter_province=${filter_province}`
        if (isArray(month)) {
            month.forEach(e => {
                url += `&month=${e}`
            });
        }
        const { data } = await API.get(url)
        let color = [
            '#27509b',
            '#f0f0f0',
        ]
        switch (which) {
            case "frontWheel":
                await setProductScreenGraphAverageFront({
                    labels: data.data.labels,
                    datasets: data.data.datasets.map((e, index) => {
                        const _color = color[(data.data.datasets.length - 1) - index]
                        return {
                            label: e.label,
                            data: e.data,
                            borderColor: _color,
                            backgroundColor: _color,
                            tension: 0.4
                        }
                    }),
                })
                break;
            case "backWheel":
                await setProductScreenGraphAverageRear({
                    labels: data.data.labels,
                    datasets: data.data.datasets.map((e, index) => {
                        const _color = color[(data.data.datasets.length - 1) - index]
                        return {
                            label: e.label,
                            data: e.data,
                            borderColor: _color,
                            backgroundColor: _color,
                            tension: 0.4
                        }
                    }),
                })
                break;
        }
    }
    const getProductScreenProvinceSales = async (month = [], year = "", filter_rd = "", filter_ad = "", filter_region = null, filter_province = null, which = "") => {
        let url = `/dashboard/provinceSales/${which}?`
        // if (month) url += `&month=${month}`
        if (year) url += `&year=${year}`
        if (filter_rd) url += `&filter_rd=${filter_rd}`
        if (filter_ad) url += `&filter_ad=${filter_ad}`
        if (filter_region) url += `&filter_region=${filter_region}`
        if (filter_province) url += `&filter_province=${filter_province}`
        if (isArray(month)) {
            month.forEach(e => {
                url += `&month=${e}`
            });
        }
        const { data } = await API.get(url)


        switch (which) {
            case "frontWheel":
                if (data.data.length > 0) {
                    let model = {
                        data: [
                            ['จังหวัด', '70/90-17', '80/90-14', '90/80-14', '110/70-12', '110/70-13', '110/70-14', '120/70-15'],
                        ],
                        colors: ['#FF6384']
                    }

                    if (isArray(data.data) && data.data.length > 0) {
                        data.data.forEach(e => {
                            if (e) model.data.push([e.province, e['70/90-17'], e['80/90-14'], e['90/80-14'], e['110/70-12'], e['110/70-13'], e['110/70-14'], e['120/70-15'],])
                        });
                        model.colors = ['#28A228']
                    }

                    // model.data = data.data
                    // console.log("medel", model)
                    await setProductScreenProvinceSalesFront(model)
                }
                break;
            case "backWheel":
                if (data.data.length > 0) {
                    let model = {
                        data: [
                            ['จังหวัด', '80/90-17', '90/90-14', '100/80-14', '130/70-13', '140/70-14'],
                        ],
                        colors: ['#FF6384']
                    }

                    if (isArray(data.data) && data.data.length > 0) {
                        data.data.forEach(e => {
                            if (e) model.data.push([e.province, e['80/90-17'], e['90/90-14'], e['100/80-14'], e['130/70-13'], e['140/70-14'],])
                        });
                        model.colors = ['#28A228']
                    }

                    // model.data = data.data
                    // console.log("medel", model)
                    await setProductScreenProvinceSalesRear(model)
                }
                break;
        }
    }
    const getProductScreenAdSalesStatus = async (month = [], year = "", filter_rd = "", filter_ad = "", filter_region = null, filter_province = null, which = "") => {
        let url = `/dashboard/adSalesStatus/${which}?`
        // if (month) url += `&month=${month}`
        if (year) url += `&year=${year}`
        if (filter_rd) url += `&filter_rd=${filter_rd}`
        if (filter_ad) url += `&filter_ad=${filter_ad}`
        if (filter_region) url += `&filter_region=${filter_region}`
        if (filter_province) url += `&filter_province=${filter_province}`
        if (isArray(month)) {
            month.forEach(e => {
                url += `&month=${e}`
            });
        }
        const { data } = await API.get(url)

        switch (which) {
            case "frontWheel":
                if (data.data.length > 0) {
                    let model = {
                        data: [
                            ['ชื่อร้าน', '70/90-17', '80/90-14', '90/80-14', '110/70-12', '110/70-13', '110/70-14', '120/70-15'],
                        ],
                        colors: ['#FF6384']
                    }

                    if (isArray(data.data) && data.data.length > 0) {
                        data.data.forEach(e => {
                            if (e) model.data.push([e.customer_name, e['70/90-17'], e['80/90-14'], e['90/80-14'], e['110/70-12'], e['110/70-13'], e['110/70-14'], e['120/70-15'],])
                        });
                        model.colors = ['#28A228']
                    }

                    // model.data = data.data
                    // console.log("medel", model)
                    await setProductScreenAdSalesStatusFront(model)
                }
                break;
            case "backWheel":
                if (data.data.length > 0) {
                    let model = {
                        data: [
                            ['ชื่อร้าน', '80/90-17', '90/90-14', '100/80-14', '130/70-13', '140/70-14'],
                        ],
                        colors: ['#FF6384']
                    }

                    if (isArray(data.data) && data.data.length > 0) {
                        data.data.forEach(e => {
                            if (e) model.data.push([e.customer_name, e['80/90-17'], e['90/90-14'], e['100/80-14'], e['130/70-13'], e['140/70-14'],])
                        });
                        model.colors = ['#28A228']
                    }

                    // model.data = data.data
                    // console.log("medel", model)
                    await setProductScreenAdSalesStatusRear(model)
                }
                break;
        }
    }

    const [rdAllInfoData, setRdAllInfoData] = useState({
        data: [
            [
                "dealer_name",
                "Sell In Target",
                "Sell In Actual",
                "Sell In %",
                "Sell Out Target",
                "Sell Out Actual",
                "Sell Out %",
                "Ad Register Target",
                "Ad Register Actual",
                "Ad Register %",
                "Ad Active Target",
                "Ad Active Actual",
                "Ad Active %"
            ],
        ],
        colors: ['#FF6384']
    })

    const rdAllInfo = async (month = [], year = "") => {
        let url = `/dashboard/rdAllInfo?`
        // if (month) url += `&month=${month}`
        if (year) url += `&year=${year}`
        if (isArray(month)) {
            month.forEach(e => {
                url += `&month=${e}`
            });
        }
        const { data } = await API.get(url)

        if (data.data.length > 0) {
            // console.log("obj", Object.keys(data.data[0]))
            let model = {
                data: [
                    [
                        "ชื่อ RD",
                        "Sell In Target",
                        "Sell In Actual",
                        "%",
                        "Sell Out Target",
                        "Sell Out Actual",
                        "%",
                        "Ad Register Target",
                        "Ad Register Actual",
                        "%",
                        "Ad Active Target",
                        "Ad Active Actual",
                        "%"
                    ],
                ],
                colors: ['#FF6384']
            }

            if (isArray(data.data) && data.data.length > 0) {
                data.data.forEach(e => {
                    if (e) model.data.push(
                        [
                            e.dealer_name,
                            e['Sell In Target'],
                            e['Sell In Actual'],
                            e['Sell In %'],
                            e['Sell Out Target'],
                            e['Sell Out Actual'],
                            e['Sell Out %'],
                            e['Ad Register Target'],
                            e['Ad Register Actual'],
                            e['Ad Register %'],
                            e['Ad Active Target'],
                            e['Ad Active Actual'],
                            e['Ad Active %'],
                        ])
                });
                model.colors = ['#28A228']
            }

            // model.data = data.data
            // console.log("medel", model)
            await setRdAllInfoData(model)
        }
    }

    const [stockLatestData, setStockLatest,] = useState([])
    const [stockHistoryData, setStockHistory] = useState([])
    const getStockLatest = async (month = [], year = "", filter_rd = "", filter_ad = "", filter_region = null, filter_province = null, which = "") => {
        let url = `/dashboard/stockLatest?`
        // if (month) url += `&month=${month}`
        if (year) url += `&year=${year}`
        if (filter_rd) url += `&filter_rd=${filter_rd}`
        if (filter_ad) url += `&filter_ad=${filter_ad}`
        if (filter_region) url += `&filter_region=${filter_region}`
        if (filter_province) url += `&filter_province=${filter_province}`
        if (isArray(month)) {
            month.forEach(e => {
                url += `&month=${e}`
            });
        }
        const { data } = await API.get(url)
        await setStockLatest(data.data)
        // console.log("stockLatest", data.data)

    }

    const getStockHistory = async (month = [], year = "", filter_rd = "", filter_ad = "", filter_region = null, filter_province = null, which = "") => {
        let url = `/dashboard/stockHistory?`
        // if (month) url += `&month=${month}`
        if (year) url += `&year=${year}`
        if (filter_rd) url += `&filter_rd=${filter_rd}`
        if (filter_ad) url += `&filter_ad=${filter_ad}`
        if (filter_region) url += `&filter_region=${filter_region}`
        if (filter_province) url += `&filter_province=${filter_province}`
        if (isArray(month)) {
            month.forEach(e => {
                url += `&month=${e}`
            });
        }
        const { data } = await API.get(url)
        await setStockHistory(data.data.data)
        // console.log("stockHistory", data.data.data)

    }
    const [rdGraphByProductType, setRdGraphByProductType] = useState([])
    const getRdGraphByProductType = async (month = [], year = "", filter_rd = "", filter_ad = "", filter_region = null, filter_province = null, which = "") => {
        let url = `/dashboard/rdGraphByProductType?`
        // if (month) url += `&month=${month}`
        if (year) url += `&year=${year}`
        if (filter_rd) url += `&filter_rd=${filter_rd}`
        if (filter_ad) url += `&filter_ad=${filter_ad}`
        if (filter_region) url += `&filter_region=${filter_region}`
        if (filter_province) url += `&filter_province=${filter_province}`
        if (isArray(month)) {
            month.forEach(e => {
                url += `&month=${e}`
            });
        }
        const { data } = await API.get(url)
        await setRdGraphByProductType(data.data.data)
        // console.log("rdGraphByProductType", data.data)

    }

    const [_one, set_one] = useState(1)
    const [testData, setTestData] = useState(null)
    const getDashboard = async ({ month = [], quarter = "", year = "", filter_rd = "", filter_ad = "", product_limit = 10, product_month_limit = 10, customer_limit = 10, rd_over = rdOver, ad_over = adOver, ad_over_1 = adOve1, filter_region = null, filter_province = null }) => {
        try {
            setLoading(true)
            let url = `/dashboard?product_limit=${product_limit}&product_month_limit=${product_month_limit}&customer_limit=${customer_limit}&rd_over=${rd_over}&ad_over=${ad_over}&ad_over_1=${ad_over_1}`
            // if (month) url += `&month=${month}`
            if (year) url += `&year=${year}`
            if (filter_rd) url += `&filter_rd=${filter_rd}`
            if (filter_ad) url += `&filter_ad=${filter_ad}`
            if (filter_region) url += `&filter_region=${filter_region}`
            if (filter_province) url += `&filter_province=${filter_province}`
            if (quarter) url += `&quarter=${quarter}`
            if (isArray(month)) {
                month.forEach((e, i) => {
                    month[i] = e.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false
                    })
                });
            }
            if (isArray(month)) {
                month.forEach(e => {
                    url += `&month=${e}`
                });
            }
            const { data } = await API.get(url)



            // console.log("gggg", data)
            /* setFilter */
            // setFilter({ ...filter, month: listMonth, ...data.filter })
            // console.log(`data.result`, data.result)

            if (_one === 1) {
                if (isPlainObject(data.result.dealer)) {
                    await onChangeRd(data.result.dealer.id)
                    form.setFieldsValue({
                        rd: data.result.dealer.id,
                    })
                    setfilter_is_ad(true)
                } else {
                    setfilter_is_ad(false)
                    form.setFieldsValue({
                        rd: null,
                    })
                }
                set_one(2)
            }


            onChangeMontYear(Number(month), Number(year))

            /* RD */
            if (isPlainObject(data.result.rd))
                setRd(data.result.rd)
            else setRd(null)

            /* AD */
            if (isPlainObject(data.result.ad))
                setAd(data.result.ad)
            else setAd(null)

            /* Sales */
            if (isPlainObject(data.result.sales))
                setSales(data.result.sales)
            else setSales(null)

            /* dealer */
            if (isPlainObject(data.result.dealer))
                setDealer(data.result.dealer)
            else setDealer(null)

            /* Target */
            if (isPlainObject(data.result.target_sale))
                // console.log('data.result.target_sale', data.result.target_sale)
                setTarget({
                    target_dn: data.result.target_sale.target_dn,
                    target_sale: data.result.target_sale.target_sale
                })
            else setTarget({
                target_dn: 0,
                target_sale: 0
            })

            /*Sell out */
            if (isPlainObject(data.result?.sell_out)) {
                setSellOut(data.result?.sell_out)
            } else {
                setSellOut({
                    target: 0,
                    actual: 0,
                    percent_acheivement: 0,
                })
            }
            /*Sell in */
            if (isPlainObject(data.result?.sell_in)) {
                setSellIn(data.result?.sell_in)
            } else {
                setSellIn({
                    target: 0,
                    actual: 0,
                })
            }

            /*Sell in Price*/
            if (isPlainObject(data.result?.sell_in_price)) {
                setSellInPrice(data.result?.sell_in_price)
            } else {
                setSellInPrice({
                    target: 0,
                    actual: 0,
                })
            }

            /*AD Register*/
            if (isPlainObject(data.result?.ad_register)) {
                setAdRegister(data.result?.ad_register)
            } else {
                setAdRegister({
                    target: 0,
                    actual: 0,
                    percent_acheivement: 0,
                })
            }
            /*DN Active*/
            if (isPlainObject(data.result?.dn_active)) {
                setDnActive(data.result?.dn_active)
            } else {
                setDnActive({
                    target: 0,
                    actual: 0,
                    percent_acheivement: 0,
                })
            }

            const color = [
                '#3342C4',
                '#ADD495',
                '#2A505A',
                '#9362C4',
                '#9DCAEB',
                '#916848',
                '#EEB189',
                '#86A5C4',
                '#F9EEB6',
                '#4BC0C0',
                '#36A2EB',
            ]
            /* Pie Dashboard */
            const dataPie = {
                data: [
                    ['รายการ', 'จำนวน (เส้น)'],
                ],
                colors: []
            }

            if (isArray(data.result.product)) {
                data.result.product.forEach(e => {
                    dataPie.data.push([e.product_name["th"], e.total_qty])
                });
                dataPie.colors = color
                // console.log("Top 10 Product", dataPie.data)
                setPieDashboard(dataPie)
            }

            /* Bar Dashboard RD */
            if (isArray(data.result.rd_graph) && data.result.rd_graph.length > 0) {
                const dataBarRD = {
                    data: [
                        ['', 'ยอดขาย', { type: 'string', role: 'tooltip', p: { html: true } }],
                    ],
                    colors: []
                }
                let abc = (
                    <p>
                        <div>abd</div>
                        <div>abd</div>
                    </p>
                )
                data.result.rd_graph.forEach(e => {
                    if (isPlainObject(e)) dataBarRD.data.push([e.dealer_name["th"], e.total_qty, `${e.total_qty.toLocaleString()} ณ วันที่ : ${moment(e.invoice_date_latest).format("DD/MM/YYYY")}`])
                });
                dataBarRD.colors = ['#27509b']
                let a = []
                data.result.rd_graph.forEach(e => {
                    a.push([e.dealer_name["th"]])
                })
                let b = []
                data.result.rd_graph.forEach(e => {
                    b.push(e.leisure_qty)
                })
                let c = []
                data.result.rd_graph.forEach(e => {
                    c.push(e.commuting_qty)
                })
                let d = []
                data.result.rd_graph.forEach(e => {
                    d.push([e.total_qty.toLocaleString()])
                })
                let f = []
                data.result.rd_graph.forEach(e => {
                    f.push([moment(e.invoice_date_latest).format("DD/MM/YYYY")])
                })
                const test = {
                    labels: a,
                    datasets: [
                        {
                            label: 'Leisure',
                            data: b,
                            backgroundColor: "#27509b",
                        },
                        {
                            label: 'Commuting',
                            data: c,
                            backgroundColor: "#1890ff",
                        },
                    ],
                    total_qty: d,
                    invoice_date_latest: f

                }
                // console.log("testtesttest", test)
                setTestData(test)
                // dataBarRD.colors = ['#27509b', '#1890ff']
                // console.log("dataBarRD", dataBarRD)
                setBarDashboardRD(dataBarRD)
            }

            /* Bar Dashboard AD */
            const dataBarAD = {
                data: [
                    ['ชื่อร้าน', 'ยอดขาย'],
                ],
                colors: []
            }

            if (isArray(data.result.customer) && data.result.customer.length > 0) {
                // console.log('data.result.customer', data.result.customer)
                data.result.customer.forEach(e => {
                    if (e) dataBarAD.data.push([e.customer_name["th"], e.total_qty])
                });
                dataBarAD.colors = ['#28A228']
            }
            setBarDashboardAD(dataBarAD)

            /* Line RD Chart */
            const color_sellout_sellin = [
                '#bbc7df',
                '#27509b',
            ]
            if (isPlainObject(data.result.rd_month)) {
                // console.log("data", data.result.rd_month.datasets)
                setLineRDChartDashboard({
                    labels: data.result.rd_month.labels,
                    datasets: data.result.rd_month.datasets.map((e, index) => {
                        const _color = color_sellout_sellin[(data.result.rd_month.datasets.length - 1) - index]
                        return {
                            label: e.label,
                            data: e.data,
                            borderColor: _color,
                            backgroundColor: _color,
                            tension: 0.4
                        }
                    }),
                })
            }

            if (isPlainObject(data.result.sell_in_graph)) {
                // console.log("data", data.result.sell_in_graph.datasets)
                setLineRDSellInChartDashboard({
                    labels: data.result.sell_in_graph.labels,
                    datasets: data.result.sell_in_graph.datasets.map((e, index) => {
                        const _color = color_sellout_sellin[(data.result.sell_in_graph.datasets.length - 1) - index]
                        return {
                            label: e.label,
                            data: e.data,
                            borderColor: _color,
                            backgroundColor: _color,
                            tension: 0.4
                        }
                    }),
                })
            }




            /* Line AD Chart */
            if (isPlainObject(data.result.customer_month)) {
                setLineADChartDashboard({
                    labels: data.result.customer_month.labels,
                    datasets: data.result.customer_month.datasets.map((e, index) => {
                        const _color = color[(data.result.customer_month.datasets.length - 1) - index]
                        return {
                            label: e.label,
                            data: e.data,
                            borderColor: _color,
                            backgroundColor: _color,
                            tension: 0.4
                        }
                    }),
                })
            }


            setLoading(false)

            await getProductScreenSales(month, year, filter_rd, filter_ad, filter_region, filter_province, "frontWheel")
            await getProductScreenSales(month, year, filter_rd, filter_ad, filter_region, filter_province, "backWheel")

            await getProductScreenAdSales(month, year, filter_rd, filter_ad, filter_region, filter_province, "frontWheel")
            await getProductScreenAdSales(month, year, filter_rd, filter_ad, filter_region, filter_province, "backWheel")

            await getProductScreenGraphAverage(month, year, filter_rd, filter_ad, filter_region, filter_province, "frontWheel")
            await getProductScreenGraphAverage(month, year, filter_rd, filter_ad, filter_region, filter_province, "backWheel")

            await getProductScreenProvinceSales(month, year, filter_rd, filter_ad, filter_region, filter_province, "frontWheel")
            await getProductScreenProvinceSales(month, year, filter_rd, filter_ad, filter_region, filter_province, "backWheel")

            await getProductScreenAdSalesStatus(month, year, filter_rd, filter_ad, filter_region, filter_province, "frontWheel")
            await getProductScreenAdSalesStatus(month, year, filter_rd, filter_ad, filter_region, filter_province, "backWheel")

            await getStockLatest(month, year, filter_rd, filter_ad, filter_region, filter_province)
            await getStockHistory(month, year, filter_rd, filter_ad, filter_region, filter_province)

            await getRdGraphByProductType(month, year, filter_rd, filter_ad, filter_region, filter_province)
            await rdAllInfo(month, year)
        } catch (error) {
            setLoading(false)
            console.log(`error`, error)
        }
    }

    const tooltipProductTypeContent = (leisure_qty = 0, commuting_qty = 0, total_qty, date) => {

        return `<div>ยอดขายทั้งหมด : ${total_qty}</div><div>ณ วันที่ : ${date}</div>`
    }

    const onFinish = async (value) => {
        // console.log('value', value)

        /* old */
        // const ad_over = value.month ? process.env.NEXT_PUBLIC_AD_ACTIVE_MONTH : process.env.NEXT_PUBLIC_AD_ACTIVE_YEAR;
        // const ad_over_1 = process.env.NEXT_PUBLIC_AD_ACTIVE_1;
        // const rd_over = value.month ? process.env.NEXT_PUBLIC_RD_ACTIVE_MONTH : process.env.NEXT_PUBLIC_RD_ACTIVE_YEAR;
        // setAdOver(ad_over);
        // setAdOve1(ad_over_1);
        // setRdOver(rd_over);

        /* new */
        const ad_over = value.month ? configDashboard.NEXT_PUBLIC_AD_ACTIVE_MONTH : configDashboard.NEXT_PUBLIC_AD_ACTIVE_YEAR;
        const ad_over_1 = configDashboard.NEXT_PUBLIC_AD_ACTIVE_1;
        const rd_over = value.month ? configDashboard.NEXT_PUBLIC_RD_ACTIVE_MONTH : configDashboard.NEXT_PUBLIC_RD_ACTIVE_YEAR;
        setAdOver(ad_over);
        setAdOve1(ad_over_1);
        setRdOver(rd_over);

        setTypeAdOver(value.month ? "เดือน" : "ปี");

        const yearDate = value.year.format('YYYY');
        await getDashboard(
            {
                month: value.month,
                quarter: value.quarter,
                year: yearDate,
                filter_ad: value.ad,
                filter_rd: value.rd,
                ad_over,
                ad_over_1,
                rd_over,
                filter_region: value.region ? value.region : null,
                filter_province: value.prov ? value.prov : null
            });
    }

    const [ad, setAd] = useState({
        all: 0,
        over: 0,
        ad_year: {
            amount: 0,
        },
    })

    const [target, setTarget] = useState({
        target_dn: 0,
        target_sale: 0,
    })

    const [rd, setRd] = useState(null)

    const [sales, setSales] = useState({
        all: 0,
        filter: 0,
    })

    const [dealer, setDealer] = useState(null)
    const [sellOut, setSellOut] = useState({})
    const [sellIn, setSellIn] = useState({})
    const [sellInPrice, setSellInPrice] = useState({})
    const [adRegister, setAdRegister] = useState({})
    const [dnActive, setDnActive] = useState({})

    const [barDashboardAD, setBarDashboardAD] = useState({
        data: [
            ['ชื่อร้าน', 'ยอดขาย'],
        ],
        colors: ['#FF6384']
    })

    const [barDashboardRD, setBarDashboardRD] = useState(null)

    const lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: true,
                text: ''
            }
        },

        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            },
            y1: {
                type: 'linear',
                display: false,
                position: 'right',

                // grid line settings
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
            },
        }
    }

    const barOption = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'กราฟเปรียบเทียบยอดขาย ปีล่าสุด กับ ปีก่อนหน้า',
            },
        },
    }
    const [lineRDChartDashboard, setLineRDChartDashboard] = useState(null)
    const [lineRDSellInChartDashboard, setLineRDSellInChartDashboard] = useState(null)

    const [lineADChartDashboard, setLineADChartDashboard] = useState({
        labels: [],
        datasets: [],
    })

    const [pieDashboard, setPieDashboard] = useState({
        data: [
            ['รายการ', 'จำนวน (เส้น)'],
        ],
        colors: ['#FF6384']
    })

    const [monthText, setMonthText] = useState(null)
    const [monthYear, setYearText] = useState(null)

    const onChangeMontYear = (month, year) => {
        // setMonthText(listMonth[month].id != 0 ? listMonth[month].value : null)
        setYearText(year)
    }

    const calActiveAD = (adOver) => {
        let { month } = form.getFieldValue();
        month = month ? Number(month) : 12;
        return Number(adOver) * month
    }

    const onChangeRegion = async (value) => {
        try {
            if (value) {
                const { data } = await API.get(`/master/region/byid/${value}`)
                if (data.status === "success") {
                    const { MapRegProvs } = data.data;
                    if (isArray(MapRegProvs)) setFilter({ ...filter, prov: MapRegProvs })
                }
            } else {
                setFilter({ ...filter, prov: [] })
            }
        } catch (error) {

        }
    }

    const onChangeQuarter = async (value) => {
        try {
            setfilter_quarter(value)
            form.setFieldsValue({
                month: [],
            })
            if (value == null) {
                console.log(value)
                onChangeMonth([])
            }
        } catch (error) {

        }
    }

    const showDashboard = (dashboard) => {
        switch (dashboard) {
            case "summary":
                setShowSummaryDashboard(true)
                setShowProductScreenDashboard(false)
                setShowStockDashboard(false)
                break;
            case "productscreen":
                setShowSummaryDashboard(false)
                setShowProductScreenDashboard(true)
                setShowStockDashboard(false)
                break;
            case "stock":
                setShowSummaryDashboard(false)
                setShowProductScreenDashboard(false)
                setShowStockDashboard(true)
                break;

        }
    }

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const Summarize = () => {
        const getAcheivement = (a, b) => {
            return a == 0 ? 0 : (((b / a) * 100).toFixed(2)).toLocaleString()
        }
        return (
            <>
                <div style={{ width: "100%" }} className="card-summarize-container">
                    <Row gutter={[15, 0]} style={{ display: "flex", justifyContent: "center" }}>
                        <Col flex={"20%"}>
                            <div className='card-summarize'>
                                <Card title={<b style={{ fontSize: "20px" }}>Sell In (บาท)</b>} bordered={false}>
                                    <div className='card-container'>
                                        <div className='box' style={{ fontSize: "14px" }}>Target (บาท)</div>
                                        <div className='box' style={{ fontSize: "16px" }}>{Number(sellInPrice?.target ?? 0).toLocaleString()}</div>
                                    </div>
                                    <div className='card-container'>
                                        <div className='box' style={{ fontSize: "14px" }}>Actual (บาท)</div>
                                        <div className='box' style={{ fontSize: "16px" }}>{Number(sellInPrice?.actual ?? 0).toLocaleString()}</div>
                                    </div>
                                </Card>
                            </div>
                        </Col>

                        <Col flex={"20%"}>
                            <div className='card-summarize'>
                                <Card title={<b style={{ fontSize: "20px" }}>Sell In (เส้น)</b>} bordered={false}>
                                    <div className='card-container'>
                                        <div className='box' style={{ fontSize: "14px" }}>Target (เส้น)</div>
                                        <div className='box' style={{ fontSize: "16px" }}>{Number(sellIn?.target ?? 0).toLocaleString()}</div>
                                    </div>
                                    <div className='card-container'>
                                        <div className='box' style={{ fontSize: "14px" }}>Actual (เส้น)</div>
                                        <div className='box' style={{ fontSize: "16px" }}>{Number(sellIn?.actual ?? 0).toLocaleString()}</div>
                                    </div>
                                </Card>
                            </div>
                        </Col>

                        <Col flex={"20%"}>
                            <div className='card-summarize'>
                                <Card title={<b style={{ fontSize: "20px" }}>Sell Out (เส้น)</b>} bordered={false}>
                                    <div className='card-container'>
                                        <div className='box' style={{ fontSize: "14px" }}>Target (เส้น)</div>
                                        <div className='box' style={{ fontSize: "16px" }}>{Number(sellOut?.target ?? 0).toLocaleString()}</div>
                                    </div>
                                    <div className='card-container'>
                                        <div className='box' style={{ fontSize: "14px" }}>Actual (เส้น)</div>
                                        <div className='box' style={{ fontSize: "16px" }}>{Number(sellOut?.actual ?? 0).toLocaleString()}</div>
                                    </div>
                                </Card>
                            </div>
                        </Col>

                        <Col flex={"20%"}>
                            <div className='card-summarize'>
                                <Card title={<b style={{ fontSize: "20px" }}>AD Register</b>} bordered={false}>
                                    <div className='card-container'>
                                        <div className='box' style={{ fontSize: "14px" }}>Target (ราย)</div>
                                        <div className='box' style={{ fontSize: "16px" }}>{Number(adRegister?.target ?? 0).toLocaleString()}</div>
                                    </div>
                                    <div className='card-container'>
                                        <div className='box' style={{ fontSize: "14px" }}>Actual (ราย)</div>
                                        <div className='box' style={{ fontSize: "16px" }}>{Number(adRegister?.actual ?? 0).toLocaleString()}</div>
                                    </div>
                                </Card>
                            </div>
                        </Col>

                        <Col flex={"20%"}>
                            <div className='card-summarize'>
                                <Card title={<b style={{ fontSize: "20px" }}>AD Active</b>} bordered={false}>
                                    <div className='card-container'>
                                        <div className='box' style={{ fontSize: "14px" }}>Target (ราย)</div>
                                        <div className='box' style={{ fontSize: "16px" }}>{Number(dnActive?.target ?? 0).toLocaleString()}</div>
                                    </div>
                                    <div className='card-container'>
                                        <div className='box' style={{ fontSize: "14px" }}>Actual (ราย)</div>
                                        <div className='box' style={{ fontSize: "16px" }}>{Number(dnActive?.actual ?? 0).toLocaleString()}</div>
                                    </div>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    const columns = [
        {
            title: 'ชื่อร้าน',
            dataIndex: 'dealer_name',
            key: 'dealer_name',
            align: 'center'
        },
        {
            title: 'จำนวน',
            dataIndex: 'qty',
            key: 'qty',
            align: 'center',
            render: (text) => (+text).toLocaleString()
        },
        {
            title: 'วันที่',
            dataIndex: 'balance_date',
            key: 'balance_date',
            align: 'center',
            render: (text, record, index) => {
                return <a onClick={() => router.push({
                    pathname: "Reports/InventoryReport",
                    query: {
                        rd: record.dealer_id,
                        dt: moment(text).format("YYYY-MM-DD")
                    },
                })}>{moment(text).format("DD/MM/YYYY")}</a>
            },
        },
    ];


    const popoverContent = (date, qty) => (
        <div>
            วันที่ตั้งต้น : {moment(date).format("DD/MM/YYYY")}
            <br></br>
            จำนวนตั้งต้น : {(+qty).toLocaleString()}
            <br></br>
            เกิดจากการคำนวณโดยมีจำนวนสต๊อค และวันที่ล่าสุดจาก Dealer
            <br></br>
            โดยจะเพิ่มด้วย Sell In และ ลบด้วย Sell Out คิดหลังจากวันที่ตั้งต้นจาก Dealer
        </div>
    );

    const footer = (tooltipItems) => {
        return `ยอดขายรวม : ${testData.total_qty[tooltipItems[0].dataIndex]} ข้อมูล ณ วันที่ ${testData.invoice_date_latest[tooltipItems[0].dataIndex]}`;
    };


    return (
        <Layouts>
            <Head>
                <title>Dashboard</title>
            </Head>

            <div gutter={[10, 10]} style={{ marginBottom: 10, marginTop: 15, textAlign: "center", borderRadius: "10px" }}>
                <Button className={!showSummaryDashboard ? 'dashboard-switch-btn' : 'dashboard-switch-btn-active'} onClick={() => showDashboard("summary")} style={{ margin: "0 10px" }}>Summary Dashboard</Button>
                <Button className={!showProductScreenDashboard ? 'dashboard-switch-btn' : 'dashboard-switch-btn-active'} onClick={() => showDashboard("productscreen")} style={{ margin: "0 10px" }}>Product Screen Dashboard</Button>
                <Button className={!showStockDashboard ? 'dashboard-switch-btn' : 'dashboard-switch-btn-active'} onClick={() => showDashboard("stock")} style={{ margin: "0 10px" }}>Stock Dashboard</Button>
            </div>

            <div className='p-3'>
                <Form form={form} colon={false} onFinish={onFinish} >
                    <Row gutter={[10, 10]} style={{ paddingTop: 35 }}>
                        <Col xl={4} lg={24} md={24} />
                        <Col xl={16} lg={24} md={24}>
                            <Row gutter={[10, 10]}>

                                <Col lg={12} md={12} span={24}>
                                    <Form.Item label="ปี" name="year" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                                        <DatePicker picker="year" style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col lg={12} md={12} span={24}>
                                    <Form.Item label="ไตรมาส" name="quarter" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} >
                                        <Select style={{ width: "100%" }} disabled={loading} placeholder="เลือกไตรมาส" onChange={onChangeQuarter}>
                                            <Select.Option value={null} >-</Select.Option>
                                            {filter.quarter.map((e, index) => <Select.Option value={e.id} key={`quarter-${index}`}>{e.value}</Select.Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={12} md={12} span={24} >
                                    <Form.Item label="เดือน" name="month" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                                        <Select mode="multiple" disabled={loading} placeholder="เลือกเดือน" disabled={(filter_quarter == null) ? false : true} onChange={(value) => onChangeMonth(value)}>
                                            {filter.month.map((e, index) => <Select.Option disabled={monthDisable[index]} value={e.id} key={`month-${index}`}>{e.value}</Select.Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>


                                {
                                    isArray(filter_rd) ?
                                        <Col lg={12} md={12} span={24}>
                                            <Form.Item label="RD" name="rd" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                                                <Select
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    disabled={loading || !isArray(filter_rd) || filter_is_ad}
                                                    placeholder="เลือก RD"
                                                    style={{ width: "100%" }}
                                                    onChange={(value) => onChangeRd(value)}
                                                >
                                                    <Select.Option value={null}>ทั้งหมด</Select.Option>
                                                    {isArray(filter_rd) ? filter_rd.map((e, index) => <Select.Option value={e.id} key={`dealer_name-${index}`}>{e.dealer_name.th}</Select.Option>) : null}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        : null
                                }



                                <Col lg={12} md={12} span={24}>
                                    <Form.Item label="AD" name="ad" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                                        <Select
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            disabled={loading || !isArray(filter_ad)}
                                            placeholder="เลือก AD"
                                            style={{ width: "100%" }}
                                            onChange={(value) => onChangeAd(value)}
                                        >
                                            <Select.Option value={null}>ทั้งหมด</Select.Option>
                                            {isArray(filter_ad) ? filter_ad.map((e, index) => <Select.Option value={e.id} key={`customer_name-${index}`}>{e.customer_name.th}</Select.Option>) : null}
                                        </Select>
                                    </Form.Item>
                                </Col>



                                <Col lg={12} md={12} span={24}>
                                    <Form.Item label="เขตการขาย" name="region" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                                        <Select style={{ width: "100%" }} disabled={loading || disabled_prov} placeholder="เลือกเขตการขาย" onChange={onChangeRegion}>
                                            <Select.Option value={null}>ทั้งหมด</Select.Option>
                                            {filter.region.map((e, index) => <Select.Option value={e.id} key={`region-${index}`}>{e.name_th}</Select.Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={12} md={12} span={24}>
                                    <Form.Item label="จังหวัด" name="prov" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                                        <Select style={{ width: "100%" }} disabled={loading || disabled_region} placeholder="เลือกจังหวัด">
                                            <Select.Option value={null}>ทั้งหมด</Select.Option>
                                            {filter.prov.map((e, index) => <Select.Option value={e.id} key={`prov-${index}`}>{e.prov_name_th}</Select.Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {/* <Col lg={12} md={12} span={24} /> */}
                                <Col lg={24} md={12} span={24}>
                                    <Form.Item style={{ textAlign: "end" }}>

                                        <Button type="primary" style={{ width: 150 }} htmlType="submit" loading={loading}>ค้นหา</Button>

                                        <span style={{ paddingLeft: 10 }} />

                                        <Button style={{ width: 150 }} type="default" onClick={reset} disabled={loading}>
                                            <ReloadOutlined spin={loading} /> ค่าเริ่มต้น
                                        </Button>
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Col>
                        <Col xl={4} lg={24} md={24} />
                    </Row>
                </Form>
            </div >



            <div hidden={!showSummaryDashboard}>


                <Card gutter={[10, 10]} style={{ marginBottom: "10px" }}>
                    <Summarize />
                </Card>

                <Row gutter={[10, 10]} >


                    {lineRDChartDashboard ?

                        <>
                            <Col xl={12} lg={12} md={24} span={24}>
                                <Card title={<span>Trend of Sell In</span>} bordered={false} >
                                    <div style={{ height: 470 }}>
                                        {!loading ? <Bar options={barOption} data={{
                                            labels: lineRDSellInChartDashboard.labels,
                                            datasets: lineRDSellInChartDashboard.datasets,
                                        }} /> : null}
                                    </div>
                                </Card>
                            </Col>

                            <Col xl={12} lg={12} md={24} span={24}>
                                <Card title={<span>Trend of Sell Out</span>} bordered={false} >
                                    <div style={{ height: 470 }}>
                                        {!loading ? <Bar options={barOption} data={{
                                            labels: lineRDChartDashboard.labels,
                                            datasets: lineRDChartDashboard.datasets,
                                        }} /> : null}
                                    </div>
                                </Card>
                            </Col>

                            {
                                barDashboardRD ?
                                    <Col xl={24} lg={24} md={24} span={24}>
                                        <Card title={<span>RD Sellout {monthYear} by Monthly</span>} bordered={false} >
                                            <div style={{ height: 300 }}>
                                                {!loading ? <Bar options={{
                                                    indexAxis: 'y',
                                                    responsive: true,
                                                    scales: {
                                                        x: {
                                                            stacked: true,
                                                        },
                                                        y: {
                                                            stacked: true
                                                        }
                                                    },
                                                    maintainAspectRatio: false,
                                                    plugins: {
                                                        legend: {
                                                            position: 'right',
                                                        },
                                                        title: {
                                                            display: true,
                                                            // text: 'กราฟเปรียบเทียบยอดขาย ปีล่าสุด กับ ปีก่อนหน้า',
                                                        },
                                                        tooltip: {
                                                            callbacks: {
                                                                footer: footer,
                                                            }
                                                        }
                                                    },
                                                }} data={{
                                                    labels: testData.labels,
                                                    datasets: testData.datasets,
                                                }} /> : null}
                                            </div>
                                        </Card>
                                    </Col>
                                    // <Col xl={24} lg={24} md={24} span={24}>
                                    //     <Card title={<span>RD Sellout {monthYear} by Monthly</span>} bordered={false} >
                                    //         <Chart
                                    //             width={'100%'}
                                    //             height={'275px'}
                                    //             chartType="BarChart"
                                    //             loader={<div>Loading Chart</div>}
                                    //             data={barDashboardRD.data}

                                    //             options={{
                                    //                 chartArea: { width: "70%" },
                                    //                 // Material design options
                                    //                 colors: barDashboardRD.colors,
                                    //                 chart: {
                                    //                     title: '',
                                    //                 },
                                    //                 bars: 'horizontal',
                                    //                 hAxis: { format: 'decimal' },
                                    //                 Tooltip: { isHtml: true },
                                    //                 focusTarget: "category",
                                    //                 isStacked: true
                                    //             }}
                                    //             // For tests
                                    //             rootProps={{ 'data-testid': '2' }}
                                    //         />

                                    //     </Card>
                                    // </Col> 
                                    : null
                            }

                        </>
                        :
                        null
                    }


                    <Col xl={24} lg={24} md={24} span={24} >
                        <Row gutter={[10, 10]}>

                            <Col xl={12} lg={12} md={24} span={24}>
                                <Card title={<span>Top 10 Product</span>} bordered={false} >
                                    <Row justify="center">
                                        <Col style={{ width: "100%" }} >
                                            <Chart
                                                height={'300px'}
                                                chartType="Table"
                                                loader={<div>Loading Chart</div>}
                                                data={pieDashboard.data}
                                                options={{
                                                    allowHtml: true,
                                                    showRowNumber: true,
                                                    width: "100%"
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>
                            <Col xl={12} lg={12} md={24} span={24}>
                                <Card title={<span>Top 10 - AD Sellout {monthYear}</span>} bordered={false}>
                                    <Row justify="center">
                                        <Col style={{ width: "100%" }} >
                                            <Chart
                                                height={'300px'}
                                                chartType="Table"
                                                loader={<div>Loading Chart</div>}
                                                data={barDashboardAD.data}
                                                options={{
                                                    allowHtml: true,
                                                    showRowNumber: true,
                                                    width: "100%"
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>
                        </Row>
                    </Col>
                    {
                        barDashboardRD ?
                            <Col xl={24} lg={24} md={24} span={24} >
                                <Row gutter={[10, 10]}>

                                    <Col xl={24} lg={24} md={24} span={24}>
                                        <Card title={<span>RD Summary Table</span>} bordered={false} >
                                            <Row justify="center">
                                                <Col style={{ width: "100%" }} >
                                                    <Chart
                                                        height={'300px'}
                                                        chartType="Table"
                                                        loader={<div>Loading Chart</div>}
                                                        data={rdAllInfoData.data}
                                                        options={{
                                                            allowHtml: true,
                                                            showRowNumber: true,
                                                            focusTarget: "category",
                                                            width: "100%"
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>

                                    </Col>
                                </Row>
                            </Col>
                            : null}
                </Row >
            </div>
            <div hidden={!showProductScreenDashboard}>
                <Row>
                    <Col xl={24} lg={24} md={24} span={24} >
                        <Row gutter={[10, 10]}>

                            <Col xl={12} lg={12} md={24} span={24}>
                                <Card title={<span>ยอดขายตามไซส์ล้อหน้าที่แนะนำ</span>} bordered={false} >
                                    <Row justify="center">
                                        <Col style={{ width: "100%" }} >
                                            <Chart
                                                height={'300px'}
                                                chartType="Table"
                                                loader={<div>Loading Chart</div>}
                                                data={productScreenSalesFront.data}
                                                options={{
                                                    allowHtml: true,
                                                    showRowNumber: true,
                                                    width: "100%"
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xl={12} lg={12} md={24} span={24}>
                                <Card title={<span>ยอดขายตามไซส์ล้อหลังที่แนะนำ</span>} bordered={false}>
                                    <Row justify="center">
                                        <Col style={{ width: "100%" }} >
                                            <Chart
                                                height={'300px'}
                                                chartType="Table"
                                                loader={<div>Loading Chart</div>}
                                                data={productScreenSalesRear.data}
                                                options={{
                                                    allowHtml: true,
                                                    showRowNumber: true,
                                                    width: "100%"
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>
                            <Col xl={12} lg={12} md={24} span={24}>
                                <Card title={<span>ยอดขายราย AD ตามไซส์ล้อหลังที่แนะนำ</span>} bordered={false}>
                                    <Row justify="center">
                                        <Col style={{ width: "100%" }} >
                                            <Chart
                                                height={'300px'}
                                                chartType="Table"
                                                loader={<div>Loading Chart</div>}
                                                data={productScreenAdSalesFront.data}
                                                options={{
                                                    allowHtml: true,
                                                    showRowNumber: true,
                                                    width: "100%"
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xl={12} lg={12} md={24} span={24}>
                                <Card title={<span>ยอดขายราย AD ตามไซส์ล้อหลังที่แนะนำ</span>} bordered={false}>
                                    <Row justify="center">
                                        <Col style={{ width: "100%" }} >
                                            <Chart
                                                height={'300px'}
                                                chartType="Table"
                                                loader={<div>Loading Chart</div>}
                                                data={productScreenAdSalesRear.data}
                                                options={{
                                                    allowHtml: true,
                                                    showRowNumber: true,
                                                    width: "100%"
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            {/* <Col xl={12} lg={12} md={24} span={24}>
                                <Card title={<span>กราฟค่าเฉลี่ยไซส์ล้อหน้าที่แนะนำ</span>} bordered={false} >
                                    <div style={{ height: 280 }}>
                                        {!loading ? <Line options={lineOptions} data={{
                                            labels: productScreenGraphAverageFront.labels,
                                            datasets: productScreenGraphAverageFront.datasets,
                                        }} /> : null}
                                    </div>
                                </Card>
                            </Col>
                            <Col xl={12} lg={12} md={24} span={24}>
                                <Card title={<span>กราฟค่าเฉลี่ยไซส์ล้อหลังที่แนะนำ</span>} bordered={false} >
                                    <div style={{ height: 280 }}>
                                        {!loading ? <Line options={lineOptions} data={{
                                            labels: productScreenGraphAverageRear.labels,
                                            datasets: productScreenGraphAverageRear.datasets,
                                        }} /> : null}
                                    </div>
                                </Card>
                            </Col> */}
                            <Col xl={12} lg={12} md={24} span={24}>
                                <Card title={<span>สถานะการซื้อรายจังหวัด ตามไซส์ล้อหน้าที่แนะนำ</span>} bordered={false}>
                                    <Row justify="center">
                                        <Col style={{ width: "100%" }} >
                                            <Chart
                                                height={'300px'}
                                                chartType="Table"
                                                loader={<div>Loading Chart</div>}
                                                data={productScreenProvinceSalesFront.data}
                                                options={{
                                                    allowHtml: true,
                                                    showRowNumber: true,
                                                    width: "100%"
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xl={12} lg={12} md={24} span={24}>
                                <Card title={<span>สถานะการซื้อรายจังหวัด ตามไซส์ล้อหลังที่แนะนำ</span>} bordered={false}>
                                    <Row justify="center">
                                        <Col style={{ width: "100%" }} >
                                            <Chart
                                                height={'300px'}
                                                chartType="Table"
                                                loader={<div>Loading Chart</div>}
                                                data={productScreenProvinceSalesRear.data}
                                                options={{
                                                    allowHtml: true,
                                                    showRowNumber: true,
                                                    width: "100%"
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xl={12} lg={12} md={24} span={24}>
                                <Card title={<span>สถานะการซื้อราย AD ตามไซส์ล้อหน้าที่แนะนำ</span>} bordered={false}>
                                    <Row justify="center">
                                        <Col style={{ width: "100%" }} >
                                            <Chart
                                                height={'300px'}
                                                chartType="Table"
                                                loader={<div>Loading Chart</div>}
                                                data={productScreenAdSalesStatusFront.data}
                                                options={{
                                                    allowHtml: true,
                                                    showRowNumber: true,
                                                    width: "100%"
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xl={12} lg={12} md={24} span={24}>
                                <Card title={<span>สถานะการซื้อราย AD ตามไซส์ล้อหลังที่แนะนำ</span>} bordered={false}>
                                    <Row justify="center">
                                        <Col style={{ width: "100%" }} >
                                            <Chart
                                                height={'300px'}
                                                chartType="Table"
                                                loader={<div>Loading Chart</div>}
                                                data={productScreenAdSalesStatusRear.data}
                                                options={{
                                                    allowHtml: true,
                                                    showRowNumber: true,
                                                    width: "100%"
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div hidden={!showStockDashboard}>
                <Row gutter={[10, 10]}>
                    <Col xl={12} lg={12} md={12} span={12} >
                        <Card title={"ข้อมูลสต๊อกล่าสุด"} style={{ fontSize: "24px" }}>
                            <Row >
                                {isArray(stockLatestData) ? stockLatestData.map((e, i) => (
                                    <>
                                        <Col span={24} key={i}>
                                            <Row>
                                                <Col span={24}>{e.dealer_name}
                                                    <Popover content={popoverContent(e.date_default, e.qty_default)} hidden={e.type === "history"}>
                                                        <label style={{ fontSize: "14px" }}>{e.type === "history" ? "" : " (เกิดจากการคำนวณ)"}</label>
                                                    </Popover>

                                                </Col>
                                                <Col span={6} style={{ textAlign: "center", fontSize: "16px" }}>จำนวน</Col>
                                                <Col span={6} style={{ textAlign: "center", fontSize: "16px" }}>{e.qty == null ? 'ไม่พบข้อมูล' : (+e.qty).toLocaleString()}</Col>
                                                <Col span={6} style={{ textAlign: "center", fontSize: "16px" }}>วันที่</Col>
                                                <Col span={6} style={{ textAlign: "center", fontSize: "16px" }}>
                                                    {e.type === "history" ? (
                                                        e.balance_date == null ? 'ไม่พบข้อมูล' :
                                                            <a onClick={() => router.push({
                                                                pathname: "Reports/InventoryReport",
                                                                query: {
                                                                    rd: e.id,
                                                                    dt: moment(e.balance_date).format("MM/DD/YYYY")
                                                                },
                                                            })}>{moment(e.balance_date).format("DD/MM/YYYY")}</a>)
                                                        :
                                                        (
                                                            e.balance_date == null ? 'ไม่พบข้อมูล' :
                                                                <label>
                                                                    {moment(e.balance_date).format("DD/MM/YYYY")}
                                                                </label>
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Divider />
                                    </>
                                )) : null}
                            </Row>
                        </Card>
                    </Col>
                    <Col xl={12} lg={12} md={12} span={12} >
                        <Card title="ประวัติสต๊อกจากร้านค้า">
                            <Table columns={columns} dataSource={stockHistoryData} />
                        </Card>
                    </Col>
                </Row>
            </div>
            <style jsx global>
                {`
                    .ant-card {
                        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                        border-radius:10px !important;
                    }
                    .google-visualization-table-tr-head{
                        background-color: #27509b !important;
                        color:white;
                    }
                    .google-visualization-table .gradient{
                        background-image: none !important;
                    }
                    .card-summarize-container .card-summarize .ant-card{
                        height: 300px !important;
                        width: 100%;
                    }
                    .dashboard-switch{
                        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                    }
                    .dashboard-switch-btn {
                        background-color: transparent;
                        border-radius: 15px;
                        box-sizing: border-box;
                        color: #3B3B3B;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: normal;
                        margin: 0;
                        min-height: 60px;
                        min-width: 0;
                        outline: none;
                        padding: 16px 24px;
                        text-align: center;
                        text-decoration: none;
                        transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
                        user-select: none;
                        -webkit-user-select: none;
                        touch-action: manipulation;
                        will-change: transform;
                    }


                    .dashboard-switch-btn:hover {
                        color: #fff;
                        background-color: #27509b;
                        box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
                        transform: translateY(-2px);
                        border-color:#27509b;
                    }

                    .dashboard-switch-btn:active {
                        color: #fff;
                        background-color: #27509b;
                        box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
                        transform: translateY(-2px);
                        border-color:#27509b;
                    }

                    .dashboard-switch-btn:focus {
                        color: #fff;
                        background-color: #27509b;
                        box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
                        transform: translateY(-2px);
                        border-color:#27509b;
                    }

                    .dashboard-switch-btn-active {
                        border-radius: 15px;
                        box-sizing: border-box;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: normal;
                        margin: 0;
                        min-height: 60px;
                        min-width: 0;
                        outline: none;
                        padding: 16px 24px;
                        text-align: center;
                        text-decoration: none;
                        transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
                        user-select: none;
                        -webkit-user-select: none;
                        touch-action: manipulation;
                        will-change: transform;
                        color: #fff;
                        background-color: #27509b;
                        box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
                        transform: translateY(-2px);
                        border-color:#27509b;
                        }

                        .dashboard-switch-btn-active:hover {
                            color: #fff;
                            background-color: #27509b;
                            box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
                            transform: translateY(-2px);
                            border-color:#27509b;
                        }
    
                        .dashboard-switch-btn-active:active {
                            color: #fff;
                            background-color: #27509b;
                            box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
                            transform: translateY(-2px);
                            border-color:#27509b;
                        }
    
                        .dashboard-switch-btn-active:focus {
                            color: #fff;
                            background-color: #27509b;
                            box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
                            transform: translateY(-2px);
                            border-color:#27509b;
                        }
                        .ant-divider-horizontal{
                            margin: 14px;
                        }
                `}
            </style>
        </Layouts >
    )
}

export default Dashboard
