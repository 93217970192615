export const SET_PERMISSION = (data) => {
    return (dispatch) => dispatch({
        type: "SETPERMISSION",
        payload: data
    })

}

export const SET_PERMISSION_OBJ = (data) => {
    return (dispatch) => dispatch({
        type: "SET_PERMISSION_OBJ",
        payload: data
    })

}