import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import { IconButton, } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Menu, PageHeader, Avatar, Badge, Row, Col, Dropdown, Image, Button, Form, Input, Modal, message, Checkbox } from 'antd';
import { UserOutlined, DownOutlined, BellOutlined, LoadingOutlined } from '@ant-design/icons';
import Link from 'next/link';
import API from '../util/Api';
import { Cookies } from 'react-cookie'
import { SET_MENU, SET_USERAUTH, SET_PERMISSION, SET_PERMISSION_OBJ, SET_OAUTH, SET_GROUPS } from '../redux/actions'
import router, { useRouter } from 'next/router';
import { isArray } from 'lodash';
import moment from 'moment'
import Api from '../util/Api/Api';

const { SubMenu } = Menu;
const { Sider, Footer } = Layout;


function Layouts({ children, disableheader, disablecontainer, dataserch, login }) {
    const dispatch = useDispatch();
    const routers = useRouter()
    const [open, setOpen] = React.useState(true);
    const { userAuth/*, keycloak*/ } = useSelector(({ auth }) => auth);
    const path = useRouter();
    const cookie = new Cookies();
    const [stateCookie, setStateCookie] = useState(false);
    var munu = useSelector(({ menu }) => menu);
    const firstUpdate = useRef(true);
    const [loading, setLoading] = useState(true)
    const [events, setEvents] = useState([
        "load",
        "mousemove",
        "mousedown",
        "click",
        "scroll",
        "keypress"])

    useEffect(() => {
        const access_token = cookie.get("access_token");
        if (access_token && !loading) {
            let timeout;
            const setTime = () => {
                timeout = setTimeout(() => {
                    Logout()
                    clearTimeout(timeout);
                }, 1800000); // 1800000 = 30 min
            }
            for (var i in events) {/*ตรวจจับทุกอีเวน์ในการเคลื่อนไหว*/
                window.addEventListener(events[i], () => {
                    clearTimeout(timeout);
                    setTime()
                });
            }
        }
    })

    useEffect(() => {
        const access_token = cookie.get("access_token");
        if (!access_token) {
            window.location.href = "/Login";
        } else {
            Getmydata()
        }
    }, [])


    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            setStateCookie(cookie.get('yes') ? true : false);
            return;
        }

    }, [])


    const Logout = () => {
        console.log('first')
        Api.get('/logout').then(async (data) => {
            removeCookieUserAuth()
        }).catch((eror) => {
            console.log(`eror`, eror)
            removeCookieUserAuth()
        })
    }

    const removeCookieUserAuth = () => {
        cookie.remove("access_token", { path: '/' });
        cookie.remove("refresh_token", { path: '/' });
        dispatch(SET_PERMISSION([]));
        window.location.href = "/Login";
    }

    const Getmydata = () => Api.get('/user/mydata', {
        headers: {
            'Authorization': `Bearer ${/*userAuth ? */userAuth.access_token/* : keycloak.token*/}`
        }
    }).then((data) => {
        // console.log(`data`, data.data.data)
        dispatch(SET_USERAUTH(data.data.data));
        dispatch(SET_GROUPS(isArray(data.data.data.Groups) && data.data.data.Groups.length > 0 ? data.data.data.Groups[0] : null));
        dispatch(SET_MENU(data.data));
        dispatch(SET_PERMISSION(data.data.data.Permission));
        dispatch(SET_OAUTH(data.data.data.Oauth));
        checkUrlPermission(data.data.data.Permission)
        setLoading(false)
    }).catch((error) => {
        console.log('error :>> ', error);
        if (!login) Logout()
    })

    const checkUrlPermission = (item) => {
        const url = item.map(e => e.url)
        const pathname = routers.pathname
        const index = url.findIndex(e => e == pathname)
        if (index === -1) {
            if (pathname != "/Login")
                router.push("/404");
        } else {
            dispatch(SET_PERMISSION_OBJ(item[index]));
        }

    }


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [checkChangePassword, setCheckChangePassword] = useState(false);
    const [form] = Form.useForm();

    const Rendermenuuser = () => {

        const handleOk = () => {
            form.submit()
        };

        const handleCancel = () => {
            setIsModalVisible(false);
        };

        function checkError(data, model) {
            let erroe_message = "ไม่สามารถบันทึกข้อมูลมูลได้ มีบางอย่างผิดพลาด"
            if (data.data == "same name") {
                erroe_message = "ไม่สามารถบันทึกข้อมูลมูลได้ ชื่อผู้ใช้ซ้ำ"
                model.user_name = null
            } else if (data.data == "same e-mail") {
                erroe_message = "ไม่สามารถบันทึกข้อมูลมูลได้ อีเมล์ซ้ำ"
                model.e_mail = null
            }

            message.warning(erroe_message);
            form.setFieldsValue({ ...model, password: null, c_password: null });
        }

        const onFinish = async (value) => {
            try {
                // console.log(`value`, value)

                let err = false

                const modelEdit = {
                    e_mail: value.e_mail,
                    note: value.note, // edit
                }

                if (value.password || value.c_password) {
                    if (value.password != value.c_password) {
                        message.warning('รหัสผ่านไม่ตรงกัน !!');
                        form.setFieldsValue({ ...modelEdit, password: null, c_password: null });
                        err = true
                    } else {
                        modelEdit.password = value.password
                        modelEdit.c_password = value.c_password
                    }
                }

                if (!err) {
                    console.log(`modelEdit`, modelEdit)
                    const { data } = await API.put(`/user/profile/put`, modelEdit)
                    // console.log('data :>> ', data);
                    if (data.status == "failed") {
                        checkError(data, modelEdit)
                    } else {
                        message.success("บันทึกข้อมูลสำเร็จ");
                        setIsModalVisible(false);
                    }
                    setIsModalVisible(false);
                }
            } catch (error) {
                message.error('มีบางอย่างผิดพลาด ไม่สามารถบันทึกได้ !!');
            }
        }
        const onFinishFailed = (error) => {
            message.warning('กรอกข้อมูลไม่ครบถ้วน กรุณากรอกข้อมูลให้ครบ !!');
        }

        const getUser = async () => {
            try {
                const { data } = await API.get(`/user/byid/${userAuth.id}`)
                if (data.status === "successful") {
                    const item = data.data[0]
                    console.log(`item`, item)
                    form.setFieldsValue({
                        user_name: item.user_name,
                        e_mail: item.e_mail,
                        note: item.note,
                    })
                } else {
                    message.warning('ไม่พบข้อมูล !!');
                }

                setIsModalVisible(true)
            } catch (error) {
                message.error('มีบางอย่างผิดพลาด !!');
            }
        }

        return (
            <>
                <Menu style={{ minWidth: 150 }}>
                    <Menu.Item key="0" onClick={getUser} >
                        <span style={{ color: "#000" }}>Profile</span>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="1" style={{ backgroundColor: "rgb(38 80 155)", color: "white" }} onClick={Logout}>Logout</Menu.Item>
                </Menu>

                <Modal
                    maskClosable={false}
                    title={userAuth.user_name}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                >
                    <Form

                        form={form}
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 14 }}
                        layout="horizontal"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            name="user_name"
                            label=" ชื่อผู้ใช้"
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            name="e_mail"
                            label="อีเมล์"
                            rules={[{ type: "email", required: true, message: "กรุณาใส่อีเมล์ของคุณ" },
                            {
                                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                message: 'Please only English',
                            }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item name="note" label="Note" >
                            <Input.TextArea rows={5} />
                        </Form.Item>

                        <Form.Item name="checkChangePassword" label="เปลี่ยน Password" >
                            <Checkbox onChange={(value) => setCheckChangePassword(value.target.checked)}></Checkbox>
                        </Form.Item>

                        {checkChangePassword ?
                            <>
                                <Form.Item
                                    name="password"
                                    type="password"
                                    label="รหัสผ่าน"
                                    rules={[
                                        {
                                            required: true,
                                            message: "กรุณาใส่รหัสผ่านของคุณ"
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item
                                    name="c_password"
                                    type="c_password"
                                    label="ยืนยันรหัสผ่าน"
                                    rules={[
                                        {
                                            required: true,
                                            message: "กรุณายืนยันรหัสผ่าน"
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </>
                            : null}

                    </Form>
                </Modal>
            </>
        )
    }

    const NotificationMenu = () => {
        return (
            <Menu style={{ minWidth: 300 }}>
                <Menu.Item key="0">Not Data</Menu.Item>
            </Menu>
        )
    }

    const acceptCookie = () => {
        cookie.set("yes", true, {
            path: "/",
            expires: new Date(moment().add(1, 'Y').format('YYYY-MM-DD'))
        });
        setStateCookie(true);
    }

    return (
        <Layout id={`layout-body`} style={{ minHeight: '100vh' }}>
            {!loading ?
                <>

                    <PageHeader
                        style={{ backgroundColor: "#27509B", padding: "0px 19px" }}
                        ghost={false}
                        onBack={() => setOpen(!open)}
                        title={
                            <Link href={`/`} prefetch >
                                <a>
                                    <img preview={false} src={"/img/Michelin_logo_02.svg"} width={"100%"} height={"100%"} style={{ maxWidth: "250px" }} />
                                </a>
                            </Link>
                        }
                        backIcon={
                            <>
                                {
                                    !login ?
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            aria-label="open drawer"
                                            onClick={() => setOpen(true)}
                                        >
                                            <MenuIcon style={{ color: "#FFF", fontSize: "40px", }} />
                                        </IconButton> : null
                                }


                            </>
                        }
                        extra={[

                            userAuth ?
                                <>
                                    <span style={{ cursor: "pointer" }}>
                                        <Dropdown overlay={NotificationMenu} trigger={['click']}>
                                            <Avatar key={0} size={45} icon={<BellOutlined />} style={{ backgroundColor: "#FFFFFF00", color: "#FFF" }} />
                                        </Dropdown>
                                    </span>
                                    <Dropdown overlay={Rendermenuuser} trigger={['click']}>
                                        <Button shape="round" icon={userAuth ? <Avatar icon={<UserOutlined />} style={{ backgroundColor: "#FFF", color: "#000" }} /> : ""} size={'large'} ghost style={{ margin: 10, paddingBottom: 37 }}>
                                            {userAuth.user_name} <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                </> : null
                        ]}
                    >
                    </PageHeader >
                    <Layout>
                        <Sider collapsedWidth={0} width={405} className="site-layout-background" style={{ backgroundColor: "#3D3D3D", zIndex: 99999 }} collapsed={open}>
                            <Menu
                                mode="inline"
                                // defaultSelectedKeys={[path.pathname]}
                                selectedKeys={[path.pathname]}
                                style={{ height: '100%', borderRight: 0, backgroundColor: "#27509B", color: "#FFF" }}
                            >
                                {
                                    munu?.data?.MenuList.map(text => {
                                        return text.children && text.children.length > 0 ?
                                            <SubMenu key={text.id} title={text.application_name}>
                                                {
                                                    text.children.map((sub, index) => (
                                                        isArray(sub.children) && sub.children.length > 0 ?

                                                            <SubMenu key={sub.id} title={sub.application_name} >
                                                                {sub.children.map((sub2, index) => (
                                                                    <Menu.Item key={sub2.url} onClick={() => path.push(sub2.url)}>
                                                                        <Link href={sub2.url} prefetch ><span style={{ color: "white" }}>{sub2.application_name}</span></Link>
                                                                    </Menu.Item>
                                                                ))}
                                                            </SubMenu>
                                                            :
                                                            <Menu.Item key={sub.url} onClick={() => path.push(sub.url)}>
                                                                <Link href={sub.url} prefetch ><span style={{ color: "white" }}>{sub.application_name}</span></Link>
                                                            </Menu.Item>

                                                    ))
                                                }
                                            </SubMenu>
                                            :
                                            <Menu.Item key={text.url} onClick={() => path.push(text.url)} ><Link href={text.url} prefetch ><span style={{ color: "white" }}>{text.application_name}</span></Link></Menu.Item>
                                    })
                                }
                            </Menu>
                            <p></p>
                        </Sider>
                        <Layout>
                            {/*!disableheader && <Headers dataserch={dataserch} />*/}
                            <Layout className={!disablecontainer ? "container" : ""}>
                                {children}
                            </Layout>
                            <Footer style={{ backgroundColor: "#27509B", color: "white" }}>
                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={12}>
                                                ระบบ Michelin 2W Protocol
                                            </Col>
                                            <Col span={8}>
                                                รุ่นโปรแกรม {process.env.NEXT_PUBLIC_APP_VERSION}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={24}>
                                                <div className="link" style={{ textAlign: 'right' }}>
                                                    <div style={{ position: 'revert', }}>
                                                        อัพเดทวันที่ {process.env.NEXT_PUBLIC_APP_LAST_UPDATE}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Footer>
                            {!stateCookie ?
                                <div className="myDiv" style={{ display: `${stateCookie ? "none" : "block"}` }}>
                                    <p className="txt-cookie">เว็บไซต์นี้ใช้ "คุกกี้" เพื่อวัตถุประสงค์ในการพัฒนาการเข้าถึงบริการของผู้ใช้ให้ดียิ่งขึ้น
                                        หากต้องการเปิดใช้งานคุกกี้</p>
                                    <p className="txt-cookie">โปรดคลิก "ยอมรับ" คุณสามารถถอนการยินยอมของคุณได้ตลอดเวลา โดยไปที่ "การตั้งค่าคุกกี้"</p>
                                    <button onClick={acceptCookie} className="button-accept txt-cookie">ยอมรับคุกกี้</button>
                                    <div id="mydiv1" className="close-cookies">x</div>
                                </div>
                                : null}
                        </Layout>

                    </Layout>
                </>
                :

                <div className='loading-page'>
                    <LoadingOutlined style={{
                        fontSize: 100,
                        color: "#1890ff",
                    }} />
                </div>}
            <style jsx global>{`
            .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
                background-color: rgb(41, 128, 185);
            }
            .ant-page-header-heading-left {
                overflow: unset;
            }
            .ant-menu-sub.ant-menu-inline {
                background: rgb(65, 64, 64);
            }  
            .myDiv {
                background-color: #b5b4b4;
                opacity: 0.9;
                text-align: center;
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 10px;
                color: white;
                z-index: 5;
            }
            .close-cookies {
                text-align: right;
                position: absolute;
                top: 0;
                right: 40px;
                color: #fff;
                font-size: 20px;
                font-weight: 400;
                cursor: pointer;
            }
            .txt-cookie{
                color: black;
            }
            .link>a{
                color:#F4D03F;
            }
            
            .MuiIconButton-root {
                border-radius: 0% !important;
            }
            .MuiIconButton-root:hover {
                border-radius: 0% !important;
            }
            .ant-menu-title-content {
                color: #fff
            }
            .loading-page{
                display: flex;
                height: 100vh;
                justify-content: center;
                align-items: center;
            }

            `}</style>
        </Layout >
    )
}

export default Layouts
